export const environment = {
  production: false,
  caretakerUrl: "https://caretaker.qa.service.75f.io/api/v1",
  siloUrl: "https://silo.qa.service.75f.io",
  buildingAnalyticsUrl: "https://bas.qa.service.75f.io",
  reportingUrl: "https://auto-cx-qa.75f.io",
  publicUrl: "/reports/public",
  messagingUrl: "https://messaging.qa.service.75f.io/api/v1",
  gatekeeperUrl: "https://gatekeeper.qa.service.75f.io",
  appSecret: "i4V6iNfmeX1PN0IsafOO8uG1f2i2FKMx",
  fddUrl: "https://fdd.qa.service.75f.io"
};
